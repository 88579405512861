<template>
    <div class="media-list">
        <mall-banner
            page-position="图文制作/拉页"
            router-name="前往墙面定制"
            router-path="/metope">
            <!-- 搜索框 -->
            <template #search>
                <search-component
                    :search-value="search"
                    :selected-value="selectedType"
                    :select-options="testArray1"
                    @handleSearch="arg => handleFun(arg.search,arg.type,selectedStyle,selectedSort)">
                </search-component>
            </template>
        </mall-banner>
        <div class="container">
            <!-- filter -->
            <list-filter
                :sort-list="testSort"
                :count-num="670"
                :selected-sort="selectedSort"
                @handleSort="arg => handleFun(search,selectedType,selectedStyle,arg)">
                <template #filter>
                    <filter-list
                        filter-title="分类："
                        :filter-array="testArray1"
                        :select-value="selectedType"
                        @handleClick="arg => handleFun(search,arg,selectedStyle,selectedSort)">
                    </filter-list>
                    <filter-list
                        filter-title="风格："
                        :filter-array="testArray2"
                        :select-value="selectedStyle"
                        @handleClick="arg => handleFun(search,selectedType,arg,selectedSort)">
                    </filter-list>
                </template>
            </list-filter>
            <!-- list -->
            <ul class="media-list-item clearfix">
                <li v-for="(item, index) in goods_list"
                    :key="index">
                    <goods-item
                        router-path="mediaDetail"
                        :goods-item="item">
                    </goods-item>
                </li>
            </ul>
            <!-- page -->
            <div class="media-list-page">
                <el-pagination
                    layout="prev, pager, next, jumper"
                    :page-count="100"
                    :pager-count="11"
                    @current-change="arg => handleFun(search,selectedType,selectedStyle,selectedSort,arg)">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from "vuex";
    
    export default {
        data() {
            let search = sessionStorage.getItem('search') || '',
                selectedType = sessionStorage.getItem('type') || '0',
                selectedStyle = sessionStorage.getItem('style') || '0',
                selectedSort = sessionStorage.getItem('sort') || '';
            return {
                search,
                selectedType,
                selectedStyle,
                selectedSort,
                testArray1: [
                    {
                        value: '1',
                        label: "拉页"
                    },
                    {
                        value: '2',
                        label: "相册"
                    },
                    {
                        value: '3',
                        label: "台历"
                    },
                    {
                        value: '4',
                        label: "名片"
                    },
                    {
                        value: '5',
                        label: "宣传单"
                    },
                    {
                        value: '6',
                        label: "明信片"
                    },
                ],
                testArray2: [
                    {
                        value: 1,
                        label: "童稚风格"
                    },
                    {
                        value: 2,
                        label: "田园风格"
                    },
                    {
                        value: 3,
                        label: "时尚风格"
                    },
                    {
                        value: 4,
                        label: "简约风格"
                    },
                    {
                        value: 5,
                        label: "民族风格"
                    },
                    {
                        value: 6,
                        label: "其它风格"
                    },
                ],
                testSort: [
                    {
                        value: 1,
                        label: "热门推荐"
                    },
                    {
                        value: 2,
                        label: "最新上传"
                    },
                    {
                        value: 3,
                        label: "使用最多"
                    }
                ],
                goods_list: [
                    {
                        id: "112787920409342126",
                        img: "http://www.ijustsharing.com/jx/static/wall/skin/DW0000003.jpg",
                        name: "DW0000003",
                        owner_avatar: "http://www.ijustsharing.com:80/jx/static/upload/1575850766316.jpg",
                        owner_gender: 1,
                        owner_name: "Mei：）",
                        release: "2020-07-10",
                        sell: 97
                    },{
                        id: "112787920409342126",
                        img: "http://www.ijustsharing.com/jx/static/wall/skin/DW0000003.jpg",
                        name: "DW0000003",
                        owner_avatar: "http://www.ijustsharing.com:80/jx/static/upload/1575850766316.jpg",
                        owner_gender: 1,
                        owner_name: "Mei：）",
                        release: "2020-07-10",
                        sell: 97
                    },{
                        id: "112787920409342126",
                        img: "http://www.ijustsharing.com/jx/static/wall/skin/DW0000003.jpg",
                        name: "DW0000003",
                        owner_avatar: "http://www.ijustsharing.com:80/jx/static/upload/1575850766316.jpg",
                        owner_gender: 1,
                        owner_name: "Mei：）",
                        release: "2020-07-10",
                        sell: 97
                    },{
                        id: "112787920409342126",
                        img: "http://www.ijustsharing.com/jx/static/wall/skin/DW0000003.jpg",
                        name: "DW0000003",
                        owner_avatar: "http://www.ijustsharing.com:80/jx/static/upload/1575850766316.jpg",
                        owner_gender: 1,
                        owner_name: "Mei：）",
                        release: "2020-07-10",
                        sell: 97
                    },{
                        id: "112787920409342126",
                        img: "http://www.ijustsharing.com/jx/static/wall/skin/DW0000003.jpg",
                        name: "DW0000003",
                        owner_avatar: "http://www.ijustsharing.com:80/jx/static/upload/1575850766316.jpg",
                        owner_gender: 1,
                        owner_name: "Mei：）",
                        release: "2020-07-10",
                        sell: 97
                    }
                ]
            }
        },
        components: {
            searchComponent: () => import("@/components/imageMall/searchComponent"),
            mallBanner: () => import("@/components/makingMall/mallBanner"),
            listFilter: () => import("@/components/imageMall/listFilter"),
            filterList: () => import("@/components/equipment/filterList"),
            goodsItem: () => import("@/components/makingMall/goodsItem")
        },
        methods: {
            ...mapMutations([
                'setMetopeFilters',
                'setMetopeList',
                'setMetopePageList'
            ]),
            ...mapActions([
                'getMetopeFilters',
                'getMetopeList'
            ]),
            handleFun(search, type, style, sort, page = 1){
                sessionStorage.setItem('search', search);
                sessionStorage.setItem('type', type);
                sessionStorage.setItem('style', style);
                sessionStorage.setItem('sort', sort);
                this.search = search;
                this.selectedType = type;
                this.selectedStyle = style;
                this.selectedSort = sort;
                this.getMetopeFilters(type);
                this.getMetopeList({
                    search,
                    type,
                    style,
                    sort,
                    page
                })
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.handleFun(vm.search, vm.selectedType, vm.selectedStyle, vm.selectedSort, vm.page.current_page);
            })
        },
        beforeRouteLeave (to, from, next) {
            sessionStorage.removeItem('search');
            sessionStorage.removeItem('type');
            sessionStorage.removeItem('style');
            sessionStorage.removeItem('sort');
            this.setMetopeFilters({});
            this.setMetopeList([]);
            this.setMetopePageList({});
            next();
        }
    }
</script>

<style scoped>
.media-list{
    --color: #1f3eaa;
    margin-bottom: 50px;
}
/* list */
.media-list-item > li{
    float: left;
    width: 25%;
    padding: 10px;
    margin-top: 50px;
}

/* page */
.media-list-page{
    margin: 50px 0;
    text-align: center;
}
</style>